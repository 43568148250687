import { useQuery } from "react-query";
import { getCards } from "src/api";

interface IOptions {
  onError: (error: Error) => void;
}

export const useGetCards = (options: IOptions) => {
  return useQuery("GetCards", getCards, {
    ...options,
    refetchOnWindowFocus: false,
  });
};
