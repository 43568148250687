import styled from "styled-components";

export const MainTitleStyled = styled.div`
  margin: 40px 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.blueDianne};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  ${({ theme }) => theme.mediaQueries.xxl} {
    font-size: 28px;
    line-height: 34px;
  }
`;

export const WrapperStyled = styled.div`
  padding: 15px;
`;

export const SectionStyled = styled.section<{ margin: string }>`
  margin: ${({ margin }) => margin || "0 auto"};
`;

export const FooterStyled = styled.footer`
  padding: 40px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
`;
