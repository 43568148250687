import { ReactNode } from "react";
import ScreenLoader from "../UI/ScreenLoader/ScreenLoader";

interface IProps {
  children: ReactNode;
  isLoading: boolean;
}

export function RequestHandler({ isLoading, children }: IProps) {
  return (
    <>
      {isLoading && <ScreenLoader />}
      <>{children}</>
    </>
  );
}
