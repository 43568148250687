import { ConditionsInfo } from "../ConditionsInfo/ConditionsInfo";

export function ConditionsInfoList() {
  return (
    <>
      <ConditionsInfo heading={"Вимоги до позичальників"}>
        <p>
          Громадянство України; Вік від 18 років; Будь-яка кредитна історія.
        </p>
      </ConditionsInfo>
      <ConditionsInfo heading={"Умови отримання:"}>
        <p>
          Мінімальний термін погашення боргу (minimum and maximum period for
          repayment) становить 62 дні. Максимальний термін - 365 днів.
          <br />
          Максимальна річна відсоткова ставка, що включає позичковий відсоток, а
          також всі інші комісії і витрати за рік (maximum Annual Percentage
          Rate (APR), including interest rate plus fees and other costs for a
          year) становить 31%, а мінімальна 3%. Річний відсоток за користування
          кредитом - min 1%, max 31%.
        </p>
      </ConditionsInfo>
      <ConditionsInfo heading={"Інформація про компанію:"}>
        <p>
          Наша адреса: ТОВ «Бистрокеш», 03035, м. Київ, вул. Митрополита Василя
          Липківського, 23
        </p>
      </ConditionsInfo>
      <ConditionsInfo
        heading={
          "Інформація про повну вартість кредиту і приклад розрахунку (representative example of the total cost of the loan, including all applicable fees):"
        }
      >
        <p>
          Якщо ви взяли кредит на суму 1000 гривень на 90 днів з процентною
          ставкою 0,01% в день, то плата за користування грошима складе 0,01% в
          день, тобто 0,1 грн в день. Виходить ви повертаєте суму відсотків
          рівну 9 грн, тобто 0.1 грн * 90 днів. А також суму кредиту рівну 1 000
          грн. Таким чином, загальна сума повернення складається з суми кредиту
          1 000 і суми нарахованих відсотків за 90 дн. 9 грн і дорівнює 1090
          грн. <br />
          Мінімальний і максимальний період погашення боргу становить від 62
          днів, до 365 днів. <br />
          Максимальна річна відсоткова ставка, що включає позичковий відсоток, а
          також всі інші комісії і витрати за рік, або аналогічна ставка,
          розрахована відповідно до місцевого законодавства становить 31%
          річних.
        </p>
      </ConditionsInfo>
      <ConditionsInfo heading={"Наслідки невчасного погашення кредиту."}>
        <p>
          Якщо ви плануєте брати кредит у будь-якій Організації, ви повинні дуже
          впевнено приймати рішенні. Ми гарантуємо конфіденційність послуг що ми
          надаємо. Та у нас на сайті ви можете прозоро побачити усі варіанти
          кредитної політики будь-якої з компанії. Та на підставі данних ви
          можете розраховувати ті умови які будуть для вас максимально вигідні
          та потрібі для вас саме зараз. Саме тому вам потрібно продумати
          доцільність вашого кредиту та подбати про своєчасні виплати по
          кредиту. Тому що при невчасному погашенні кредиту можуть бути не
          завжди приємні наслідки. Ми вас обов’язково попереджаємо про них. А ви
          будьте уважні та заздалегідь плануйте свій фінансові рішення.
        </p>
      </ConditionsInfo>
      <ConditionsInfo
        heading={
          "Вас можуть очікувати саме такі негативні наслідки при не виплаті кредиту."
        }
      >
        <ol>
          <li>
            У разі прострочення виплат по кредиту вам у обов’язковому порядку
            будуть нараховуватися пені за кожен день не виплати, та штрафні
            санкції.
          </li>
          <li>
            Ваш кредитний рейтинг може бути значно погіршений у випадку
            прострочення платежу.
          </li>
          <li>
            Вам будуть надходити зауваження та пам’ятки, про ваш прострочений
            кредит в тій чи іншій організацій.
          </li>
          <li>
            Також у випадку без результатного намагання, з тягнути з вас виплати
            по кредиту може бути подана позивна заява до суду з питання
            стягнення з вас боргу, у судовому порядку.
          </li>
        </ol>
      </ConditionsInfo>
    </>
  );
}
