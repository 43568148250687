export const SETTINGS: { COUNTRY: string; LANGUAGE: string } = {
  COUNTRY:
    document
      .querySelector('meta[name="website-country"]')
      ?.getAttribute("content") || "",
  LANGUAGE:
    document
      .querySelector('meta[name="website-language"]')
      ?.getAttribute("content") || "",
};

export const URL_PARAMS = {
  CRM_ID: "crm_id",
  LEAD_ID: "lead_id",
  UTM_SMS: "utm_sms",
  UTM_SOURCE: "utm_source",
  UTM_CAMPAIGN: "utm_campaign",
  REF: "ref",
  CID: "cid",
  GCLID: "gclid",
  FB_PIXEL: "fbpixel",
  SUB_ID_2: "subid2",
  AFF_ID: "affid",
  getCrmId: function () {
    return new URL(window.location.href).searchParams.get(this.CRM_ID);
  },
  getLeadId: function () {
    return new URL(window.location.href).searchParams.get(this.LEAD_ID);
  },
  getUtmSms: function () {
    return new URL(window.location.href).searchParams.get(this.UTM_SMS);
  },
  getUtmSource: function () {
    return new URL(window.location.href).searchParams.get(this.UTM_SOURCE);
  },
  getUtmCampaign: function () {
    return new URL(window.location.href).searchParams.get(this.UTM_CAMPAIGN);
  },
  getRef: function () {
    return new URL(window.location.href).searchParams.get(this.REF);
  },
  getCid: function () {
    return new URL(window.location.href).searchParams.get(this.CID);
  },
  getGClid: function () {
    return new URL(window.location.href).searchParams.get(this.GCLID);
  },
  getFbPixel: function () {
    return new URL(window.location.href).searchParams.get(this.FB_PIXEL);
  },
  getSubId2: function () {
    return new URL(window.location.href).searchParams.get(this.SUB_ID_2);
  },
  getAffId: function () {
    return new URL(window.location.href).searchParams.get(this.AFF_ID);
  },
};

export const COOKIES = {
  value: document.cookie.split(";"),
  GA: "_ga=",
  SESSION_ID_GA4: "_ga_1W94SG3B8B",
  CRM_ID: "crm_id=",
  FBC: "_fbc",
  FBP: "_fbp",
  FB_PIXEL: "fbpixel",
  getCrmId: function () {
    const t = this.value.find((t) => t.includes(this.CRM_ID));
    return t ? t.split(this.CRM_ID)[1] : null;
  },
  setCrmId: function (t: string) {
    document.cookie = URL_PARAMS.CRM_ID + "=" + t + ";max-age=2147483647";
  },
  getGa: function () {
    const t = this.value.find((t) => t.includes(this.GA));
    if (t) {
      const e = t.split(".");
      return e[e.length - 2] + "." + e[e.length - 1];
    }
    return null;
  },
  getSessionIdGa4: function () {
    const t = this.value.find((t) => t.includes(this.SESSION_ID_GA4));
    if (t) {
      return t.split(".")[2];
    }
    return null;
  },
  getFBC: function () {
    let t = this.value.find((t) => t.includes(this.FBC));
    return (
      t && (t = t.split(this.FBC + "=")[1]), t && "undefined" !== t ? t : null
    );
  },
  getFBP: function () {
    let t = this.value.find((t) => t.includes(this.FBP));
    return (
      t && (t = t.split(this.FBP + "=")[1]), t && "undefined" !== t ? t : null
    );
  },
  getFB_PIXEL: function () {
    let t = this.value.find((t) => t.includes(this.FB_PIXEL));
    return (
      t && (t = t.split(this.FB_PIXEL + "=")[1]),
      t && "undefined" !== t ? t : null
    );
  },
};

export const EVENTS = {
  updateHrefLinks: function () {
    const search = window.location.search;
    let crmId = URL_PARAMS.getCrmId() || undefined;
    const links = document.querySelectorAll("a");

    links.forEach(function (link) {
      let href = link.getAttribute("href");
      if (search) {
        href += search;
        if (!crmId) {
          href += "&crm_id=" + (COOKIES.getCrmId() || undefined);
        }
      } else {
        crmId = crmId || COOKIES.getCrmId() || undefined;
        href?.includes("?")
          ? (href += "&crm_id=" + crmId)
          : (href += "?crm_id=" + crmId);
      }
      if (href?.includes("bystrocash.com")) {
        link.setAttribute("href", href);
      }
    });

    const interval = setInterval(function () {
      if (COOKIES.getGa()) {
        links.forEach(function (link) {
          let href = link.getAttribute("href");
          href += "&cid=" + (COOKIES.getGa() || undefined);
          href += "&session_id_ga4=" + (COOKIES.getSessionIdGa4() || undefined);
          if (href?.includes("bystrocash.com")) {
            link.setAttribute("href", href);
          }
        });
        clearInterval(interval);
      }
    }, 1000);
  },
};
