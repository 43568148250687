import styled from "styled-components";

export const WrapperStyled = styled.div<{ $isError: boolean }>`
  width: 100%;
  input {
    height: 67px;
    width: 100%;
    padding-left: 30px;
    border-radius: 16px;
    color: ${({ theme }) => theme.colors.blueDianne};
    font-size: 16px;
    border: 1px solid
      ${({ theme, $isError }) =>
        theme.colors[$isError ? "errorRed" : "metallicSilver"]};
    transition: border 0.3s;

    &:focus {
      border: 2px solid ${({ theme }) => theme.colors.santaGrey};
      outline: none;
    }
  }
`;

export const ErrorWrapperStyled = styled.div<{ $isError: boolean }>`
  opacity: ${({ $isError }) => ($isError ? "1" : "0")};
  height: 22px;
  transition: opacity 0.3s;
`;

export const ErrorStyled = styled.div`
  color: ${({ theme }) => theme.colors.errorRed};
  text-align: left;
`;
