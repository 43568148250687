import { TLender } from "src/types";
import { SETTINGS } from "./config";

export const API_CARDS_URL = process.env.REACT_APP_API_CARDS_URL;
export const API_UNSUBSCRIBE_URL = process.env.REACT_APP_API_UNSUBSCRIBE_URL;

export const BREACKPOINTS = {
  cards: `showcase/custom?perPage=100&lang=${SETTINGS.LANGUAGE}&country=${SETTINGS.COUNTRY}&land=bystro.cash`,
  unsubscribe:
    "macros/s/AKfycbwJEs_i1VB-YQHjI3hlvzV19XOhS908ljlCPs_UwqmDiHaDv6JKyMRaPvqMgYqAkmlM_w/exec",
};

export const PATHS = {
  home: "/",
  unsubscribe: "/unsubscribe",
};

export const LENDERS: TLender[] = [
  {
    id: 1,
    name: 'ТОВ "ФК" "ІНВЕСТРУМ"',
    linkPath: "https://bystrocash.com/zecredit",
    linkName: "zecredit.com.ua",
    address: "03150, Україна, м. Київ, вул. Велика Васильківська, 72",
    phone: "0800 352 706",
    email: "info@zecredit.com.ua",
    yearRate: "(APR) 912.5%",
    term: "62 дня - 1 рік",
    info: "1000 ₴ на 3 місяці, комісія 2250 ₴, загальні витрати 3250 ₴, APR 912.5%.",
  },
  {
    id: 2,
    name: 'ТОВ "СЛОН КРЕДИТ"',
    linkPath: "https://bystrocash.com/sloncredit",
    linkName: "sloncredit.ua",
    address: "03062, м. Київ, проспект Перемоги, 90-А",
    phone: "0(800)330383",
    email: "info@sloncredit.com.ua",
    yearRate: "(APR) 726.35%",
    term: "62 дня - 1 рік",
    info: "3000 ₴ на 3 місяці, комісія 5373 ₴, загальні витрати 8373 ₴, APR 726.35%.",
  },
  {
    id: 3,
    name: 'ТОВ "ФК" "ІНВЕСТРУМ"',
    linkPath: "https://bystrocash.com/banana",
    linkName: "bananacredit.com.ua",
    address: "03150, м. Київ, вул. Велика Васильківська, 72",
    phone: "+38(073)736527",
    email: "info@bananacredit.com.ua",
    yearRate: "(APR) 912.5%",
    term: "62 дня - 1 рік",
    info: "1000 ₴ на 3 місяці, комісія 1500 ₴, загальні витрати 2500 ₴, APR 912.5%.",
  },
  {
    id: 4,
    name: 'ТОВ "МАНИВЕО ШВИДКА ФІНАНСОВА ДОПОМОГА"',
    linkPath: "https://bystrocash.com/moneyveo",
    linkName: "moneyveo.ua",
    address: "01015, м. Київ, вул. Лейпцизька, 15-Б, поверх перший",
    phone: "0(800)219393",
    email: "support@moneyveo.ua",
    yearRate: "(APR) 612%",
    term: "62 дня - 1 рік",
    info: "1000 ₴ на 3 місяці, комісія 1512 ₴, загальні витрати 2512 ₴,APR 612%.",
  },
  {
    id: 5,
    name: '"АВАНС КРЕДИТ"',
    linkPath: "https://bystrocash.com/avanscredit",
    linkName: "avans.credit",
    address: "01015, м. Київ, вул. Лейпцизька, буд. 15А",
    phone: "0(800)202520",
    email: "info@avans.credit",
    yearRate: "(APR) 726.35%",
    term: "62 дня - 1 рік",
    info: "1000 ₴ на 3 місяці, комісія 1791 ₴, загальні витрати 2791 ₴, APR 726.35%.",
  },
  {
    id: 6,
    name: '"АВАНС КРЕДИТ"',
    linkPath: "https://bystrocash.com/selfie",
    linkName: "selfiecredit.com.ua",
    address: "04070, м. Київ, вул. Сковороди Григорія, 1",
    phone: "+38(044)3334356",
    email: "info@selfiecredit.com.ua",
    yearRate: "(APR) 1332.25%",
    term: "62 дня - 1 рік",
    info: "1000 ₴ на 3 місяці, комісія 1710 ₴, загальні витрати 2710 ₴, APR 693.5%.",
  },
  {
    id: 7,
    name: '"КАЧАЙ ГРОШІ"',
    linkPath: "https://bystrocash.com/kachay",
    linkName: "kachay.com.ua",
    address: "03150, м.Київ, вул. Казимира Малевича, 86-И",
    phone: "0(800)312727",
    email: "info@kachay.com.ua",
    yearRate: "(APR) 730%",
    term: "62 дня - 1 рік",
    info: "4000 ₴ на 3 місяці, комісія 5610 ₴, загальні витрати 9610 ₴, APR 730%.",
  },
  {
    id: 8,
    name: '"АВЕНТУС УКРАЇНА"',
    linkPath: "https://bystrocash.com/creditplus",
    linkName: "creditplus.ua",
    address: "03062, м. Київ, проспект Перемоги, 90-А",
    phone: "0(800)753363",
    email: "info@creditplus.ua",
    yearRate: "(APR) 726.35%",
    term: "62 дня - 1 рік",
    info: "3000 ₴ на 3 місяці, комісія 5373 ₴, загальні витрати 8373 ₴, APR 726.35%.",
  },
  {
    id: 9,
    name: '"МІЛОАН"',
    linkPath: "https://bystrocash.com/miloan",
    linkName: "miloan.ua",
    address: "м. Київ, вулиця В'ячеслава Чорновола, 12",
    phone: "+38 044 337 36 67",
    email: "info@miloan.ua",
    yearRate: "(APR) 3,65% (365 днів)",
    term: "62 дня - 1 рік",
    info: "2000 грн. на 3 місяці, комісія 402 грн., загальні витрати 2402 грн., APR 20,1%",
  },
  {
    id: 10,
    name: "ТОВ «ЛІНЕУРА УКРАЇНА»",
    linkPath: "https://bystrocash.com/credit7",
    linkName: "credit7.ua",
    address: "02081, м. Київ, Дніпровська набережна,25, п.318",
    phone: "+38 097 733 07 33",
    email: "info@credit7.ua",
    yearRate: "(APR) 3,65% (365 днів)",
    term: "62 дня - 1 рік",
    info: "2000 грн. на 3 місяці, комісія 402 грн., загальні витрати 2402 грн., APR 20,1%",
  },
  {
    id: 11,
    name: "ТОВ «БЕЗПЕЧНЕ АГЕНТСТВО НЕОБХІДНИХ КРЕДИТІВ»",
    linkPath: "https://bystrocash.com/mycredit",
    linkName: "mycredit.ua",
    address: "01010, м. Київ, площа Арсенальна, 1-Б",
    phone: "0(800)214111",
    email: "support@mycredit.ua",
    yearRate: "(APR) 726.35%",
    term: "62 дня - 1 рік",
    info: "3000 ₴ на 3 місяці, комісія 5373 ₴, загальні витрати 8373 ₴, APR 726.35%.",
  },
  {
    id: 12,
    name: "ТОВ «УКР КРЕДИТ ФИНАНС&»",
    linkPath: "https://bystrocash.com/creditkasa",
    linkName: "creditkasa.com.ua",
    address: "01133, м. Київ, бул. Лесі Українки, 26, оф. 407",
    phone: "0(800)200221",
    email: "info@creditkasa.ua",
    yearRate: "(APR) 912.5%",
    term: "62 дня - 1 рік",
    info: "1000 ₴ на 3 місяці, комісія 2250 ₴, загальні витрати 3250 ₴, APR 912.5%.",
  },
];
