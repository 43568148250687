import { BrowserRouter, Route, Routes } from "react-router-dom";
import { InstallPWA } from "src/components/InstallPWA/InstallPWA";

import { ROUTES } from "src/router/routes";

export function Navigation() {
  return (
    <BrowserRouter>
      <Routes>
        {ROUTES.map((route) => {
          const { path, element: Component } = route;
          return (
            <Route
              key={path}
              path={path}
              element={
                <RouteWrapper>
                  <Component />
                </RouteWrapper>
              }
            />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
}

const RouteWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      {children}
      <InstallPWA />
    </>
  );
};
