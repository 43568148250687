import { Card } from "../Card/Card";
import { useGetCards } from "src/hooks";
import { toast } from "react-toastify";
import { RequestHandler } from "../RequestHandler/RequestHandler";
import { TCard } from "src/types";
import { EVENTS } from "src/constants/config";
import { useEffect } from "react";

export function CardsList() {
  const { isLoading, data: cards } = useGetCards({
    onError(error: Error) {
      toast.error(error.message);
    },
  });

  const perPage = parseInt(
    (document
      .querySelector('meta[name="website-per-page"]')
      ?.getAttribute("content") || "15") as string,
  );

  const cardsList = cards?.data?.data?.entities?.splice(0, perPage);

  useEffect(() => {
    if (cardsList && cardsList?.length) EVENTS.updateHrefLinks();
  }, [cardsList]);

  return (
    <RequestHandler isLoading={isLoading}>
      {cardsList?.map((card: TCard) => {
        return <Card key={card.id} card={card} />;
      })}
    </RequestHandler>
  );
}
