import styled from "styled-components";

export const HeaderStyled = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
`;

export const MainStyled = styled.main`
  margin: 40px 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.blueDianne};
`;

export const WrapStyled = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
`;

export const AboutSmsStyled = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 65px 40px;
  max-width: 532px;
  text-align: center;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const AboutSmsHeadingStyled = styled.h2`
  margin-bottom: 20px;
  max-width: 400px;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: ${({ theme }) => theme.colors.blueDianne};
`;

export const RowStyled = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 20px;
`;

export const AboutSmsInfoStyled = styled.p`
  text-align: center;
  max-width: 400px;
`;

export const FormStyled = styled.form`
  width: 100%;
  padding: 40px 0 0;
`;

export const BtnWrapStyled = styled.div`
  width: 100%;
  position: relative;
`;
