import styled from "styled-components";

export const ContainerStyled = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 540px;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 720px;
  }
  ${({ theme }) => {
    return theme.mediaQueries.lg;
  }} {
    max-width: 1280px;
  }
`;

export const RowStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;

export const TitleStyled = styled.div`
  margin-bottom: 20px;
  line-height: 1.2;
  font-weight: 500;
  font-size: 18px;
  color: rgb(255, 255, 255);
  padding: 15px;
  background: rgb(48, 130, 236);
`;
