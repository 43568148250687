import { ReactNode } from "react";
import { HeadingStyled, WrapStyled } from "./styled";

interface IProps {
  children: ReactNode;
  heading: string;
}

export function ConditionsInfo({ children, heading }: IProps) {
  return (
    <WrapStyled>
      <HeadingStyled>{heading}</HeadingStyled>
      {children}
    </WrapStyled>
  );
}
