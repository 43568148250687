import {
  ContainerStyled,
  RowStyled,
  TitleStyled,
} from "src/styles/commonStyled";
import {
  FooterStyled,
  MainTitleStyled,
  SectionStyled,
  WrapperStyled,
} from "./styled";
import { LENDERS, PATHS } from "src/constants/constants";
import { Link } from "react-router-dom";
import { ConditionsInfoList } from "src/components/ConditionsInfoList/ConditionsInfoList";
import { InfoList } from "src/components/InfoList/InfoList";
import { Lender } from "src/components/Lender/Lender";
import { CardsList } from "src/components/CardsList/CardsList";

export function HomePage() {
  return (
    <ContainerStyled>
      <main>
        <SectionStyled margin={"5px 0 50px 0"}>
          <MainTitleStyled>
            Подавши декілька заявок до різних фінансових установ, шанси на
            отримання кредиту збільшуються!
          </MainTitleStyled>
          <RowStyled>
            <CardsList />
          </RowStyled>
        </SectionStyled>
        <SectionStyled margin={"0 0 50px 0"}>
          <TitleStyled>Інформація про кредитодавців</TitleStyled>
          <WrapperStyled>
            <RowStyled>
              {LENDERS.map((lender) => {
                return <Lender key={lender.id} lender={lender} />;
              })}
            </RowStyled>
          </WrapperStyled>
        </SectionStyled>
        <SectionStyled margin={"0 0 120px 0"}>
          <TitleStyled>Вимоги та умови для отримання кредиту</TitleStyled>
          <ConditionsInfoList />
        </SectionStyled>
        <SectionStyled margin={"0 0 90px 0"}>
          <RowStyled>
            <InfoList />
          </RowStyled>
        </SectionStyled>
      </main>
      <FooterStyled>
        <Link to={PATHS.unsubscribe}> Відписатись від розсилки</Link>
      </FooterStyled>
    </ContainerStyled>
  );
}
