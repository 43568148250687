import { TLender } from "src/types";
import { LeadHeadingStyled, LeadWrapStyled } from "./styled";

export function Lender({
  lender: {
    name,
    linkPath,
    linkName,
    address,
    phone,
    email,
    yearRate,
    term,
    info,
  },
}: {
  lender: TLender;
}) {
  return (
    <LeadWrapStyled>
      <LeadHeadingStyled>
        {`${name} — `}
        <a href={linkPath}>{linkName}</a>
      </LeadHeadingStyled>
      <p>Адреса: {address}</p>
      <p>Телефон: {phone}</p>
      <p>Е-пошта: {email}</p>
      <p>Max ГПС {yearRate}</p>
      <p>Термін: {term}</p>
      <p>{info}</p>
    </LeadWrapStyled>
  );
}
