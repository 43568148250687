import { MouseEvent, useEffect, useState } from "react";
import { InstallPWAButton } from "./styled";

export const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState<boolean>(false);
  const [promptInstall, setPromptInstall] = useState<Event | null>(null);

  useEffect(() => {
    const handler = (e: Event) => {
      e.preventDefault();

      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  const onClick = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    setSupportsPWA(false);
    (promptInstall as any).prompt();
  };

  if (!supportsPWA) {
    return null;
  }

  return <InstallPWAButton onClick={onClick}>Скачати додаток</InstallPWAButton>;
};
