import styled from "styled-components";

export const BoxStyled = styled.div`
  position: fixed;
  z-index: 11111;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: 0;
  background: rgba(0, 0, 0, 0);
  border-radius: 24px;
  background-color: hsla(0, 0%, 100%, 0.35);
  transition:
    opacity 0.3s,
    visibility 0.3s;
`;

export const WrapperStyled = styled.div`
  z-index: 1111;
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LoadBlockStyled = styled.div`
  font-size: inherit;
  text-align: center;

  & span {
    display: inline-block;
    width: 1em;
    height: 1em;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.colors.pearlAqua};
    animation: sk-bouncedelay 1.4s ease-in-out infinite both;

    &:nth-child(1) {
      animation-delay: -0.32s;
    }

    &:nth-child(2) {
      animation-delay: -0.16s;
    }
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    to {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 0.35;
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0.95;
    }
  }
  @keyframes sk-bouncedelay {
    0%,
    80%,
    to {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 0.35;
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0.95;
    }
  }
`;
