import { baseColors } from "src/theme/colors";
import { ButtonTheme, EButtonVariants } from "./types";

export const buttonTheme: ButtonTheme = {
  [EButtonVariants.Default]: {
    background: baseColors.pink,
    backgroundHover: baseColors.pinkHover,
    color: baseColors.white,
    colorHover: baseColors.white,
    border: "none",
    borderHover: "none",
  },
};
