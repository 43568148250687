import { ContainerStyled } from "src/styles/commonStyled";
import {
  AboutSmsHeadingStyled,
  AboutSmsInfoStyled,
  AboutSmsStyled,
  BtnWrapStyled,
  FormStyled,
  HeaderStyled,
  MainStyled,
  RowStyled,
  WrapStyled,
} from "./styled";
import { Button } from "src/components/UI/Button/Button";
import { EButtonVariants } from "src/components/UI/Button/types";
import { PhoneNumberInput } from "src/components/FormFields/PhoneNumberInput/PhoneNumberInput";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { unsubscribeSchema } from "src/validation/schemas/unsubscribeSchema";
import { useUnsubscribe } from "src/hooks/mutations";
import { toast } from "react-toastify";
import { RequestHandler } from "src/components/RequestHandler/RequestHandler";

export interface FormValues {
  phone: string;
}

export function UnsubscribePage() {
  const methods = useForm<FormValues>({
    resolver: yupResolver(unsubscribeSchema),
    mode: "onChange",
    defaultValues: {
      phone: "",
    },
  });
  const { handleSubmit, watch } = methods;
  const values = watch();

  const { mutate: unsubscribe, isLoading } = useUnsubscribe({
    onSuccess({ data }: { data: { result: string; row: number } }) {
      if (data?.result === "success")
        toast.success(
          "Ми отримали вашу заявку, рекламні комунікації за вашим номером будуть припинені через 24 години",
        );
    },
    onError(error) {
      toast.error((error as Error).message);
    },
  });

  const onSubmit = (formData: FormValues) => {
    const formDataToSend = new FormData();
    formDataToSend.append("phone", `+${formData.phone}`);
    formDataToSend.append("sheetName", "UA");

    unsubscribe(formDataToSend);
  };

  return (
    <RequestHandler isLoading={isLoading}>
      <ContainerStyled>
        <HeaderStyled />
        <MainStyled>
          <RowStyled>
            <WrapStyled>
              <AboutSmsStyled>
                <AboutSmsHeadingStyled>
                  Відмова від новин і актуальної інформації
                </AboutSmsHeadingStyled>
                <AboutSmsInfoStyled>
                  Натиснувши кнопку «Відмовитися» ти безповоротно відмовишся від
                  отримання новин на свій телефон
                </AboutSmsInfoStyled>
                <FormProvider {...methods}>
                  <FormStyled onSubmit={handleSubmit(onSubmit)}>
                    <RowStyled>
                      <PhoneNumberInput name="phone" value={values.phone} />
                    </RowStyled>
                    <BtnWrapStyled>
                      <Button variant={EButtonVariants.Default}>
                        Відмовитися
                      </Button>
                    </BtnWrapStyled>
                  </FormStyled>
                </FormProvider>
              </AboutSmsStyled>
            </WrapStyled>
          </RowStyled>
        </MainStyled>
      </ContainerStyled>
    </RequestHandler>
  );
}
