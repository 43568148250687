import { AxiosRequestHeaders, AxiosResponse } from "axios";
import { UseMutationOptions, useMutation } from "react-query";
import { unsubscribe } from "src/api";

export const useUnsubscribe = (
  options: Omit<
    UseMutationOptions<
      AxiosResponse<
        { result: string; row: number },
        { headers: AxiosRequestHeaders }
      >,
      unknown,
      FormData,
      unknown
    >,
    "mutationKey" | "mutationFn"
  >,
) => {
  return useMutation("Unsubscribe", unsubscribe, {
    ...options,
  });
};
