import { PATHS } from "src/constants/constants";
import { HomePage } from "src/pages/Home/Home";
import { UnsubscribePage } from "src/pages/Unsubscribe/Unsubscribe";

import { TRoute } from "src/types";

export const ROUTES: TRoute[] = [
  {
    path: PATHS.home,
    element: HomePage,
  },
  {
    path: PATHS.unsubscribe,
    element: UnsubscribePage,
  },
];
