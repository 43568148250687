import { Colors } from "./types";

export const baseColors = {
  blueDianne: "#2d4055",
  stormGrey: "#6F7781",
  lightGrey: "#d9d8da",
  clearBlue: "#3082ec",
  pink: "#d48adb",
  pinkHover: "#c768d1",
  white: "#ffffff",
  metallicSilver: "#c3c4c8",
  errorRed: "#ff3b30",
  pearlAqua: "#75dcc3",
  santaGrey: "#999ea4",
  harvestGold: "#dbbc5e",
};

export const lightColors: Colors = {
  ...baseColors,
};
