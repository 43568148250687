import { Heading, InfoItem, InfoItemText } from "./styled";

export function InfoList() {
  return (
    <>
      <InfoItem>
        <Heading>Важлива інформація. Будь ласка, уважно прочитайте</Heading>
        <InfoItemText>
          Особи, що стикаються з важкими фінансовими труднощами, повинні
          розглянути інші варіанти або звернутися за кваліфікованою фінансовою
          консультацією. Цей веб-сайт не пропонує кредитних послуг.
          Creditify.com.ua не є кредитором чи кредитним партнером і не приймає
          рішень стосовно кредитів або фінансування. Creditify.com.ua допомагає
          зацікавленим особам знайти кредитора чи кредитного партнера в своїй
          мережі підтверджених кредиторів та кредитних партнерів.
          Creditify.com.ua не контролює і не несе відповідальність за дії чи
          бездіяльність будь-якого кредитора чи кредитного партнера.
          Creditify.com.ua не є представником, агентом, брокером або кредитним
          партнером жодного кредитора або кредитного партнера і не рекомендує
          жодного з них. Creditify.com.ua отримує винагороду від своїх
          кредиторів та кредитних партнерів. Однак, послуги Creditify.com.ua
          завжди залишаються безкоштовними для вас. Радимо вам уважно
          ознайомитися і зрозуміти умови будь-якого запропонованого кредиту та
          відмовитися від будь-якої конкретної кредитної пропозиції, яку ви не
          можете собі дозволити чи яка містить неприйнятні для вас умови.
          Надаючи свою інформацію через цей сайт, ви надаєте дозвіл партнерам
          Creditify.com.ua проводити кредитну перевірку і аналіз вашої
          платоспроможності.
        </InfoItemText>
      </InfoItem>
      <InfoItem>
        <Heading>
          Розкриття умов кредитором або партнером по кредитуванню.
        </Heading>
        <InfoItemText>
          Кредитори чи кредитні партнери, з якими ви спілкуєтеся, нададуть вам
          документи, які містять інформацію про всі комісії та відсоткові
          ставки, пов'язані з кредитом, що вони пропонують, включаючи можливі
          штрафи за затримку платежів та умови, за якими вам може бути дозволено
          (якщо дозволено чинним законодавством) рефінансувати, поновлювати чи
          переглядати ваш кредит. Комісії та процентні ставки за кредитами
          встановлюються винятково кредитором чи кредитним партнером на основі
          їх внутрішньої політики, критеріїв страхового ризику та діючого
          законодавства. Creditify.com.ua не має доступу до та не контролює
          умови кредитування, які пропонуються кредитором чи кредитним
          партнером. Ми закликаємо вас уважно ознайомитися та зрозуміти умови
          будь-якого кредиту, запропонованого кредитором або кредитним
          партнером, та відмовитися від будь-якої конкретної кредитної
          пропозиції, яку ви не можете собі дозволити погасити або яка містить
          неприйнятні для вас умови.
        </InfoItemText>

        <Heading>
          Несвоєчасні платежі негативно впливають на кредитний рейтинг
        </Heading>
        <InfoItemText>
          Зверніть увагу, що пропуск платежу або прострочення платежу може
          негативно вплинути на вашу кредитну історію. Для захисту себе та своєї
          кредитної історії укладайте угоди лише на ті кредитні умови, які ви
          зможете виконати. Якщо ви не зможете здійснити платіж вчасно, вам слід
          негайно зв'язатися зі своїми кредиторами та кредитними партнерами і
          обговорити можливі варіанти вирішення проблеми з простроченими
          платежами.
        </InfoItemText>
      </InfoItem>
    </>
  );
}
