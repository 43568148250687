import { Navigation } from "./navigation/Navigation";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import GlobalStyle from "./styles/Global";
import "react-toastify/dist/ReactToastify.css";
import OneSignal from "react-onesignal";
import { useEffect } from "react";

const App = () => {
  const queryClient = new QueryClient();
  useEffect(() => {
    if (process.env.REACT_APP_ONE_SIGNAL_APP_ID) {
      OneSignal.init({ appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID });
    }
  }, []);

  return (
    <>
      <GlobalStyle />
      <ToastContainer style={{ zIndex: 10001 }} />
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Navigation />
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
};

export default App;
