import styled from "styled-components";

export const InstallPWAButton = styled.button`
  background: #006ce7;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  position: fixed;
  bottom: 0;
  left: 0;
  max-width: 100%;
  cursor: pointer;
  z-index: 100;
  border: none;
`;
