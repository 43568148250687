import { BoxStyled, LoadBlockStyled, WrapperStyled } from "./styles";

export function ScreenLoader() {
  return (
    <BoxStyled>
      <WrapperStyled>
        <LoadBlockStyled>
          <span></span>
          <span></span>
          <span></span>
        </LoadBlockStyled>
      </WrapperStyled>
    </BoxStyled>
  );
}

export default ScreenLoader;
