import styled from "styled-components";

export const CardWrapStyled = styled.div`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  ${({ theme }) => theme.mediaQueries.md} {
    flex: 0 0 50%;
    max-width: 50%;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    flex: 0 0 25%;
    max-width: 25%;
  }
`;

export const CardInnerWrapStyled = styled.div`
  position: relative;
  padding: 30px 20px 20px;
  margin-bottom: 40px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const CardHighlightStyled = styled.div<{ background?: string }>`
  padding: 0 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 24px 24px 0 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  background: ${({ background }) => background || "#20B2AA"};
`;

export const ImgWrapStyled = styled.div`
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImgLinkStyled = styled.a`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
`;

export const ImgStyled = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: auto;
`;

export const OfferListStyled = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const OfferListItemStyled = styled.li`
  position: relative;
  height: 66px;

  &:before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 12px;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.lightGrey};  
`;

export const OfferListItemTitleStyled = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
`;

export const OfferListItemValStyled = styled.p`
  padding: 5px 0 10px;
  color: ${({ theme }) => theme.colors.blueDianne};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const PureLinkStyled = styled.a`
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.harvestGold};
  transition: color 0.2s;
`;

export const PureLinkPinkStyled = styled.a`
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.pink};
  transition: color 0.2s;
`;

export const CardBtnLinkStyled = styled.a`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  font-family: Montserrat, sans-serif;
  height: 70px;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 500px;
  background-color: ${({ theme }) => theme.colors.pink};
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.pinkHover};
  }
`;
