import { TCard } from "src/types";
import {
  CardBtnLinkStyled,
  CardHighlightStyled,
  CardInnerWrapStyled,
  CardWrapStyled,
  ImgLinkStyled,
  ImgStyled,
  ImgWrapStyled,
  OfferListItemStyled,
  OfferListItemTitleStyled,
  OfferListItemValStyled,
  OfferListStyled,
  PureLinkPinkStyled,
  PureLinkStyled,
} from "./styled";

export function Card({ card }: { card: TCard }) {
  const {
    name,
    label,
    label_color,
    link,
    logo,
    first_loan,
    max_sum_guest,
    percent_period,
    real_year_percent,
    characteristic_link,
    characteristic,
    notice_link,
    notice,
    get_money,
  } = card.attributes;

  return (
    <CardWrapStyled>
      <CardInnerWrapStyled>
        <CardHighlightStyled background={label_color}>
          {label}
        </CardHighlightStyled>
        <ImgWrapStyled>
          <ImgLinkStyled href={link} target="_blank" rel="noopener">
            <ImgStyled src={logo} alt={name} />
          </ImgLinkStyled>
        </ImgWrapStyled>
        <OfferListStyled>
          <OfferListItemStyled>
            <OfferListItemTitleStyled>{first_loan}</OfferListItemTitleStyled>
            <OfferListItemValStyled>{max_sum_guest}</OfferListItemValStyled>
          </OfferListItemStyled>
          <OfferListItemStyled>
            <OfferListItemTitleStyled>
              Строк кредитування
            </OfferListItemTitleStyled>
            <OfferListItemValStyled>{percent_period}</OfferListItemValStyled>
          </OfferListItemStyled>
          <OfferListItemStyled>
            <OfferListItemTitleStyled>
              Реальна річна процентна ставка
            </OfferListItemTitleStyled>
            <OfferListItemValStyled>{real_year_percent}</OfferListItemValStyled>
          </OfferListItemStyled>
        </OfferListStyled>
        <PureLinkStyled
          href={characteristic_link}
          target="_blank"
          rel="noopener"
        >
          {characteristic}
        </PureLinkStyled>
        <PureLinkPinkStyled href={notice_link} target="_blank" rel="noopener">
          {notice}
        </PureLinkPinkStyled>
        <CardBtnLinkStyled href={link} target="_blank" rel="noopener">
          {get_money}
        </CardBtnLinkStyled>
      </CardInnerWrapStyled>
    </CardWrapStyled>
  );
}
