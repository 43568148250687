import axios from "axios";
import {
  API_CARDS_URL,
  API_UNSUBSCRIBE_URL,
  BREACKPOINTS,
} from "src/constants/constants";

const instanceCards = axios.create({
  baseURL: API_CARDS_URL,
});
const instanceUnsubscribe = axios.create({
  baseURL: API_UNSUBSCRIBE_URL,
});

export const getCards = () => {
  return instanceCards.get(BREACKPOINTS.cards);
};

export const unsubscribe = (data: FormData) => {
  return instanceUnsubscribe.post(BREACKPOINTS.unsubscribe, data);
};
