import { ChangeEventHandler } from "react";
import { useFormContext } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import { ErrorStyled, ErrorWrapperStyled, WrapperStyled } from "./styled";

interface IProps {
  name: string;
  value: string;
}

export function PhoneNumberInput({ name, value, ...rest }: IProps) {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const cleanValue = e.target.value.replace(/\D/g, "");
    setValue(name, cleanValue);
  };

  const error = errors?.[name]?.message;

  return (
    <WrapperStyled $isError={!!error}>
      <ReactInputMask
        value={value}
        mask="+3 (999) 999-99-99"
        placeholder="+3 (___) ___-__-__"
        onChange={handleChange}
        {...rest}
      />
      <ErrorWrapperStyled $isError={!!error}>
        {!!error && <ErrorStyled>{error as string}</ErrorStyled>}
      </ErrorWrapperStyled>
    </WrapperStyled>
  );
}
