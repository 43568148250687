import styled from "styled-components";

export const InfoItem = styled.div`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  ${({ theme }) => theme.mediaQueries.lg} {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 15px;
  }
`;

export const Heading = styled.h3`
  margin: 20px 0;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.blueDianne};
`;

export const InfoItemText = styled.p`
  color: ${({ theme }) => theme.colors.stormGrey};
`;
