import styled from "styled-components";
import { ButtonPropsType } from "./types";

export const Button = styled.button<ButtonPropsType>`
  width: 100%;
  text-align: center;
  text-indent: 0;
  font-style: normal;
  border: none;
  display: flex;
  align-items: center;
  transition: all 0.25s ease;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  padding: 16px 60px;
  gap: 10px;
  border-radius: 500px;
  font-weight: 600;
  font-size: 22px;
  line-height: 120%;
  outline: ${({ theme, variant }) => theme.buttons[variant].border};
  background: ${({ theme, variant }) => theme.buttons[variant].background};
  color: ${({ theme, variant }) => theme.buttons[variant].color};

  &:not(:disabled):hover {
    background: ${({ theme, variant }) =>
      theme.buttons[variant].backgroundHover};
    color: ${({ theme, variant }) => theme.buttons[variant].colorHover};
    outline: ${({ theme, variant }) => theme.buttons[variant].borderHover};
    svg path {
      stroke: ${({ theme, variant }) => theme.buttons[variant].colorHover};
    }
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
