import styled from "styled-components";

export const LeadWrapStyled = styled.div`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  ${({ theme }) => theme.mediaQueries.md} {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 15px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.clearBlue};
  }
`;

export const LeadHeadingStyled = styled.h3`
  margin-bottom: 10px;
  padding: 15px 0 5px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.clearBlue};
  background-color: rgba(0, 0, 0, 0);
  font-size: 1.17em;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.blueDianne};
  }
`;
